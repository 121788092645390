<template>
  <v-card max-width="300">
    <v-card-title
      class="text-subtitle-1 primary darken-1 white--text justify-center py-2 px-0"
      >{{ paquete.nombre }}
    </v-card-title>
    <v-card-text class="mb-2">
      <div class="my-6 text-center">
        <span class="body-2" style="opacity: 78%">
          Vigencia de
          {{
            paquete.duracionDias == 45
              ? "mes y medio"
              : paquete.duracionMeses + " meses"
          }}
        </span>
        <br />
        <span class="text-h5 font-weight-bold"
          >${{ (paquete.costoTotal / 1.16).toFixed(2) }}</span
        >
        <span class="caption" style="opacity: 78%">+ IVA</span>
      </div>

      <div class="mx-4 text-center_ body-2" style="opacity: 78%">
        <span v-if="paquete.numCitas != -1"
          >{{ paquete.numCitas }} consultas</span
        >
        <span v-else>Consultas ilimitadas</span>
        <v-divider class="my-1"></v-divider>
        <span v-if="paquete.amigoManik">{{ paquete.numTimbres }} timbres</span>
        <span v-else>Timbres ilimitados</span>
        <v-divider class="my-1"></v-divider>
        <span>Publicidad médica incluída</span>
        <v-divider class="my-1"></v-divider>
      </div>
    </v-card-text>
    <v-card-actions
      class="py-0 pa-0 success"
      color="success"
      @click="goToComprar"
    >
      <v-btn text block large class="white--text">
        <v-icon class="mr-1">mdi-cart</v-icon>
        COMPRAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data: () => ({ token: null }),
  props: ["paquete"],
  computed: {
    usuario: function () {
      return this.$store.state.usuario;
    },
  },
  methods: {
    goToComprar() {
      if (!this.usuario || !this.token) {
        this.$emit("unknown-user", this.paquete.id);
        alert(
          "¡Por favor índicanos cuál es tu usuario DentaManik.\n\n\nSi eres nuevo, regístrate!"
        );
        return;
      }
      var uuid = this.usuario ? this.usuario.token : this.token;
      this.$http
        .get(`/api/usuarios/obt-tkn-compra-by/${this.paquete.id}/${uuid}`)
        .then((pt) => {
          if (!pt) return;
          window.location.href = `${process.env.VUE_APP_COMPRAS_URL}/public/purchase/${this.usuario.token}/${pt}`;
          // process.env.VUE_APP_COMPRAS_URL +
          // "/comprar?at=" +
          // this.usuario.sessionUuid +
          // "&pt=" +
          // pt;
        });
    },
    setToken(t) {
      this.token = t;
    },
  },
};
</script>
